import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import IP from "../../config.js"


export const getProjectsContent = createAsyncThunk('/employees/content', async () => {
	const response = await axios.get(IP + '/api/projects', {})
    console.log(response);
	return response.data;
})

export const projectsSlice = createSlice({
    name: 'projects',
    initialState: {
        isLoading: false,
        projects: []
    },
    extraReducers: {
		[getProjectsContent.pending]: state => {
			state.isLoading = true
		},
		[getProjectsContent.fulfilled]: (state, action) => {
			state.projects = action.payload
			state.isLoading = false
		},
		[getProjectsContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export default projectsSlice.reducer
